<template>
  <div class="w-100 ">
    <section class="w-100 products" v-if="!isOpenVariant">
      <div class=""
        v-if="areNotAvailableProducts" style="height: 70vh; overflow-y: scroll; overflow-x: hidden;">
        <LoaderComp v-if="isLoadingImport"/>
        <div v-else class=" row question-not-font flex-column d-flex justify-content-center align-items-center">
        <img src="https://www.quizell.com/_nuxt/img/why_quizell_male.04fc678.svg" alt="Svg" style="width: 15rem;">
        <div class="row text-center px-2">
          <div class="col-12">
            <h3>Products Not Available</h3>
          </div>
          <!-- <div class="col-12">
            <h5>You can <router-link :to="`/manage/products`">Add Products</router-link> or <a href='javascript:void(0)' 
            @click="()=>{isLoadingImport=true; $emit('importItems',{title:'saveProducts',callBackFunction:()=>{isLoadingImport=false;}})}">
            import all products</a> from your store</h5>
          </div> -->
        </div>
</div>
      </div>
      <b-skeleton type="input" v-else-if=isLoadingProducts></b-skeleton>
      <div class="" v-else>
        <label for="search-input" class="productSearchInput col-12 row align-items-center mx-1 pr-3 py-1">
          <label  class="m-0" style="cursor: pointer">
            <i class="fa fa-search" style="color: #6d6b6d" aria-hidden="true"></i>
          </label>
          <input type="text" id="search-input"  class="mx-2 flex-grow-1" v-model="searchProduct" placeholder="Search for Product" />
          <b-dropdown size="sm" v-b-tooltip.hover.bottom="'Filter'" variant="link" dropright
            toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <i class="fa-solid fa-filter product-icon"></i>
            </template>
            <b-dropdown-item-button @click="openCollapse('price')">Price</b-dropdown-item-button>
            <b-dropdown-item-button @click="openCollapse('tags')">Tags</b-dropdown-item-button>
            <b-dropdown-item-button @click="openCollapse('collection')">Collections</b-dropdown-item-button>
            <b-dropdown-item-button @click="openCollapse('vendors')">Vendors</b-dropdown-item-button>

          </b-dropdown>
        </label>
        <!-- <div class="d-flex col-11 p-0 w-100 heading-input-search">
        <label for="search-product" class="font-weight-bold">Search</label>
        <div class="productSearchInput w-100 px-0 ml-2">
          <input id="search-product" class="w-100" type="search" name="search-products" placeholder="Enter Product"
            v-model=searchProduct />
        </div>
      </div> -->

      </div>
      <div class=""><b-collapse :visible="productFilters.isFilterVisible" id="filter-collapse" class="mt-2">
          <div class="w-100">
            <!-- Price-->

            <div class="" v-if=productFilters.price.isActive>
              <div class="productSearchInput col-12 ml-1 align-items-center">
                <div class="d-flex pr-2 align-items-center justify-content-between">
                  <div class="">
                    <b-form-select v-model="productFilters.price.selectedOption" size="sm"
                      class="product-match-select m-0">
                      <b-form-select-option selected disabled :value="null">
                        Select Price
                      </b-form-select-option>
                      <b-form-select-option value="above">
                        Above
                      </b-form-select-option>
                      <b-form-select-option value="between">
                        Between
                      </b-form-select-option>
                      >
                    </b-form-select>
                  </div>
                  <div class="w-100 col-7" v-if="productFilters.price.selectedOption == 'above'">
                    <b-form-input name="above-price" v-model="productFilters.price.above" placeholder="Above Price"
                      type="number" class="input-price" size="sm"></b-form-input>
                  </div>
                  <div class="col-4 d-flex align-items-center" v-if="productFilters.price.selectedOption == 'between'">
                    <label for="from" class="m-0" style="font-size: inherit;">From:</label>
                    <b-form-input id="from" name="From" class="input-price py=0" v-model="productFilters.price.start"
                      placeholder="Price" type="number" size="sm"></b-form-input>
                  </div>
                  <div class="col-3 d-flex align-items-center" v-if="productFilters.price.selectedOption == 'between'">
                    <label for="to" class="m-0" style="font-size: inherit;">To:</label>
                    <b-form-input id="to" v-model="productFilters.price.end" placeholder="Price" type="number"
                      class="input-price py-0" size="sm"></b-form-input>
                  </div>
                  <div class="col-2 w-100 d-flex px-0 justify-content-end">
                    <i class="fa fa-times-circle product-icon float-right" @click="resetFilter" aria-hidden="true"
                      style="cursor:pointer"></i>
                  </div>

                </div>
              </div>


            </div>
            <!-- Vendors -->
            <div v-if=productFilters.vendors.isActive>
              <div class="col-12 d-flex ml-1 align-items-center productSearchInput"> <b-form-select size="sm"
                  v-model="productFilters.vendors.selectedOPtion" class=" product-match-select m-0">
                  <b-form-select-option selected disabled :value="null">
                    Select Vendor
                  </b-form-select-option>
                  <b-form-select-option :value="vendor.title" v-for="vendor, index in VendorList" :key="index">
                    {{ vendor.title }}
                  </b-form-select-option>

                  >
                </b-form-select><i class="fa fa-times-circle product-icon float-right" @click="resetFilter"
                  aria-hidden="true" style="cursor:pointer"></i></div>
              <div class="col-12">

              </div>
            </div>
            <!-- Collections -->
            <div class="" v-if=productFilters.collections.isActive>
              <div class="col-12 d-flex ml-1 align-items-center productSearchInput">
                <multiselect class="product-match-select" v-model="productFilters.collections.selectedOPtion"
                  :options="this.CollectionList.map((tag) => tag.title)" placeholder="Collections" :multiple="true"
                  :taggable="true"></multiselect>

                <!-- <b-form-select v-model="productFilters.collections.selectedOPtion" class="m-0 product-match-select" size="sm">
                <b-form-select-option  selected disabled :value="null">
                  Select Collection
                </b-form-select-option>
                <b-form-select-option :value="collection.title" v-for="collection, index in CollectionList" :key="index">
                  {{ collection.title }}
                </b-form-select-option>

                >
              </b-form-select>  -->
                <i class="fa fa-times-circle product-icon float-right" @click="resetFilter" aria-hidden="true"
                  style="cursor:pointer"></i>
              </div>
              <div class="col-12">

              </div>
            </div>
            <!-- Tags -->
            <div class="" v-if=productFilters.tags.isActive>
              <div class="col-12 d-flex ml-1 align-items-center productSearchInput">
                <multiselect class="product-match-select" v-model="productFilters.tags.selectedOPtion"
                  :options="this.TagsList.map((tag) => tag.title)" placeholder="Tag" :multiple="true"
                  :taggable="true"></multiselect> <i class="fa fa-times-circle product-icon float-right"
                  @click="resetFilter" aria-hidden="true" style="cursor:pointer"></i>
              </div>
              <div class="col-12">
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
      <div v-if=isLoadingProducts class="col-12  mt-4 d-flex justify-content-end">
        <b-skeleton class="w-25"></b-skeleton>
      </div>
      <div v-else class="col-12  mt-4 d-flex justify-content-end">
        <div class="filterBtn mr-2" @click="resetFilter()" v-if="checkReset">Reset Filter</div>
        <div class="filterBtn" v-if="!areNotAvailableProducts && getProducts.length > 0"
          @click.stop="bulkSelectProducts">
          {{ isSelectAll?'Select All': 'Unselect All' }}</div>
      </div>
      <div class="w-100 p-3 productBox" v-if=isLoadingProducts style="border:none">
        <div class="skel">
          <div class="mb-2 py-1" v-for="index in 5" :Key="index">
            <div class="d-flex  align-items-center">
              <b-skeleton type="avatar" animation="throb"></b-skeleton>
              <b-skeleton type="input" class="ml-2 w-86" animation="throb"></b-skeleton>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="w-100 p-3 productBox" v-if="(!areNotAvailableProducts) && getProducts.length > 0" style="border:none">
        <div :class="[isSelectedProduct(product) ? 'selectedProduct' : '', 'mb-2 py-1,product-row']"
          v-for="product, prodIndex in getProducts" :key="product.id">
          <div class="d-flex align-items-center product">
            <div class="d-flex col-8 px-0 align-items-center">
              <div class="">
                <div class="custom-control custom-checkbox" role="group">
                  <input type="checkbox" :id="`quest-${prodIndex}`" class="custom-control-input position-static"
                    aria-label="Never" 
                    :value="{
                      product_id: product.id,
                      question_option_id:
                      question.options
                      [selected.optionIndex].id,}" 
                    v-model="question.options[selected.optionIndex].selected_products
                    " />
                  <label :for="`quest-${prodIndex}`" class="custom-control-label"></label>
                </div>
              </div>
              <div class="img">
                <div v-if="checkFileType(product.image) == 'video'">
                  <video ref="videoRef" :src="`${product.image}`" alt="" class="img-thumbnail mr-2" style="
                                      height: 50px;
                                      width: 50px;
                                      border-radius: 50%;
                                      object-fit: cover;
                                    " controls></video>
                </div>
                <LazyImage :src="productImage(product.image)" :key="1" alt="" class=" product-image" />
              </div>
              <div class="singleProduct d-flex align-items-center pl-2">
                <p class="title text-left">
                  {{ product.title }}
                </p>
              </div>
            </div>
            <div class=" col float-right">
              <div class=" variant-btn" @click="openVariant(product.id)">
                <div class="btn variant-text">Variants <i class="fa-solid ml-1 fa-chevron-right"></i></div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div class="w-100 p-3 productBox" v-if="!this.isLoadingProducts && products.all.length > 0 && getProducts <= 0"
        style="border:none">
        <div class="d-flex flex-column h-100 justify-content-center align-items-center text-center mb-2 py-1">
          <div class="btn" v-b-tooltip.hover.top="'Reset Filter'" @click="resetFilter()"> <i
              class="fa-solid fa-arrow-rotate-right"></i></div>
          <h5>No products were found matching your filter </h5>
        </div>
      </div>
    
    </section>
    <product-variant v-else @backFromVariant="backFromVariant()" :isLoadingProducts="isLoadingVariantProduct"
      :products="selectedVariantProducts" :selected="selected" ></product-variant>
  </div>
</template>
<script>
import LoaderComp from "../../customize/component/LoaderComp.vue";
import Multiselect from 'vue-multiselect'
import axios from 'axios'
import ProductVariant from './ProductVariant.vue'
import { mapGetters } from "vuex";
export default {
  props: {
    products: Object,
    selected: Object,
    question: Object,
    TagsList: Array,
    CollectionList: Array,
    VendorList: Array,
    isLoadingProducts: Boolean
  },
  components: {
    ProductVariant,
    Multiselect,
    LoaderComp
  },
  computed: {
    areNotAvailableProducts() {
      return this.isLoadingProducts == false && this.products.all.length <= 0 ? true : false
    },
    ...mapGetters(["getEditorSettings", "GetTokenFromMetaTag", "getQuizID"]),
    checkReset() {

      for (let index in this.productFilters) {
        if (index != 'isFilterVisible') {
          if (this.productFilters[index].selectedOPtion != null)
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.showResetBtn = true

        }
      }
      if (this.productFilters.price.above != '' || this.productFilters.price.start != '' || this.productFilters.price.end != '')
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.showResetBtn = true

      return this.showResetBtn
    },

    getProducts() {
     
      return this.products.all.filter(item => {
        return ((item.title.toLowerCase().includes(this.searchProduct.toLowerCase())) && (this.productFilters.price.above == '' ||
          (item.price > 0 && item.price >= this.productFilters.price.above)) &&
          (this.productFilters.price.start == '' ||
            this.productFilters.price.end == '' ||
            (item.price > 0 &&
              item.price >= this.productFilters.price.start &&
              item.price <= this.productFilters.price.end))
          &&
          (this.productFilters.tags.selectedOPtion == null ||  this.productFilters.tags.selectedOPtion.length<=0 &&
            (item.tags !== null &&  Array.isArray(item.tags) &&
              item.tags.some(tag => 
              { 
                this.productFilters.tags.selectedOPtion.find(selected=>selected==tag)}
                ))) &&
          (this.productFilters.collections.selectedOPtion == null ||  this.productFilters.collections.selectedOPtion.length<=0 ||
            (item.collections !== null &&  Array.isArray(item.collections) &&
           item.collections.some(collection=>{
           
            return this.productFilters.collections.selectedOPtion.find((selected=>{
              return selected==collection
            }))
           })
              )) &&
          (this.productFilters.vendors.selectedOPtion == null ||
            (item.vendor !== null &&
              item.vendor.includes(this.productFilters.vendors.selectedOPtion)))

        );
      });
    }
  },
  watch: {
  },
  data() {
    return {
      isLoadingImport:false,
      tagOptions: [],
      selectedVariantProducts:[],
      isLoadingVariantProduct:false,
      isOpenVariant: false,
      skeletonCount: 5,
      searchProduct: '',
      isSelectAll: true,
      showResetBtn: false,
      productFilters: {
        price: {
          isActive: false,
          selectedOption: null,
          above: '',
          start: '',
          end: ''
        },
        tags: {
          isActive: false,
          allTags: [],
          selectedOPtion: null,
        },
        vendors: {
          isActive: false,
          selectedOPtion: null,
          allVendors: [],
        },
        collections: {
          isActive: false,
          allCollections: [],
          selectedOPtion: null,
        },
        isFilterVisible: false
      }

    }
  },
  methods: {

   async openVariant(id) {
      this.isOpenVariant = true;
      this.selectedVariantProducts=[];
      this.isLoadingVariantProduct= true;
      await axios.post('previewProductVariants',{product_id:id}).then(response=>{
        if (response.status != 200) {
          this.$toasted.show("Error occurred ", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
          return 
          }
        this.selectedVariantProducts=response.data.data
      }).catch(()=>{
        this.$toasted.show("Error occurred ", {
            theme: "toasted-primary",
            type: "error",
            position: "bottom-center",
            duration: 2000,
          });
      })
      this.isLoadingVariantProduct=false
    },
    backFromVariant() {
      this.isOpenVariant = false
    },
    bulkSelectProducts() {
      let products = this.getProducts;
      if (this.isSelectAll) {
        this.isSelectAll = false;
        
        products.forEach(product => {
          let checkDuplicate = {
            product_id: product.id,
            question_option_id: this.question.options[
              this.selected.optionIndex
            ].id
          }
          if(!this.question.options[this.selected.optionIndex].selected_products.includes(checkDuplicate))
          this.question.options[this.selected.optionIndex].selected_products.push({
            product_id: product.id,
            question_option_id: this.question.options[
              this.selected.optionIndex
            ].id
          })
        })
        
      }
      else {
        this.question.options[
          this.selected.optionIndex
        ].selected_products = [];
        this.isSelectAll = true;
      }

    },
    resetFilter() {
      this.searchProduct = ''
      this.productFilters.isFilterVisible = false;
      this.showResetBtn = false;

      for (let index in this.productFilters) {
        if (index != 'isFilterVisible') {
          this.productFilters[index].isActive = false
          this.productFilters[index].selectedOPtion = null
        }
      }
      this.productFilters.price.start = ''
      this.productFilters.price.end = ''
      this.productFilters.price.above = ''
    },
    openCollapse(name) {
      this.productFilters.isFilterVisible = true;
      for (let index in this.productFilters) {
        if (index != name && index != 'isFilterVisible') {
          this.productFilters[index].isActive = false
          // this.productFilters[index].selectedOPtion = null
        }

      }
      // this.productFilters.price.start = ''
      // this.productFilters.price.end = ''
      // this.productFilters.price.above = ''
      if (name === 'price')
        this.productFilters.price.isActive = true;
      if (name === 'vendors')
        this.productFilters.vendors.isActive = true;
      if (name === 'tags')
        this.productFilters.tags.isActive = true;
      if (name === 'collection')
        this.productFilters.collections.isActive = true;
    },
    isSelectedProduct(product) {
      
      const findIndex = this.question.options[this.selected.optionIndex].selected_products.findIndex((x) => x.product_id == product.id);
      if (findIndex > -1) {
        return true;
      } else {
        return false;
      }
    },
  
    checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
    productImage(image) {
      if (image)
        return image.startsWith('upload') ? '/' + image : image
      return 'https://images.quizell.com/default/default_image.png'
    }
  }
}
</script>
<style >
.product-image {
  height: 50px;
  width: 50px;
  /* border: 4px solid rgb(255, 255, 255);  */
  border-radius: 50%;
  object-fit: cover;
  background-size: cover;
  padding: 0.25rem;
  background-color: #fff;
}

.product-icon {
  color: #4d1b7e;

}

.product-icon:hover {
  color: black;
}

.productSearchInput:focus-within {
  /* background: #9BE300!important; */
  /* width: 250px; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* text-align: center; */
  outline: 1px solid #4d1b7e;

}

.productSearchInput:focus-within i {
  /* background: #9BE300!important; */
  /* width: 250px; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* text-align: center; */
  color: #4d1b7e !important;

}

.productSearchInput {
  border: 0.8px solid #b3afb6;
  box-sizing: border-box;
  border-radius: 6px;
  background: #ffffff;
}

.productSearchInput input {
  background: transparent;
  border: none;
  outline: none;
}

.productSearchInput input:focus {
  border: none;
  outline: none;
}

.filterBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  letter-spacing: 0.04em;
  color: #4d1b7e;
  cursor: pointer
}

.filterBtn:hover {
  color: black;

}

.w-86 {
  width: 86%;
}

.productBox {
  height: 55vh;
  /* border: 0.5px solid #b3afb6; */
  box-sizing: border-box;
  border-radius: 10px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

.productBox .product {
  border-radius: 6px;
}

.productBox .product:hover {
  background: #b3afb614;
}

.variant-btn {
  display: none;
  font-size: 14px;
  border: 1px solid #b3afb6;
  border-radius: 12px;
  outline: none
}

.selectedProduct .product:hover .variant-btn {
  border: 1.5px solid #4d1b7e;
  color: #4d1b7e !important;
}

.selectedProduct .product:hover .variant-btn .variant-text {
  color: #4d1b7e !important;
}

.product:hover .variant-btn {
  display: block;
}

.singleProduct .title {
  color: #18191c;
  font-weight: 500;
}

.singleProduct .price {
  color: #4d4950;
  font-weight: 300;
}

.product-match-select:focus {
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.product-match-select {
  border-color: transparent !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.multiselect__option--highlight {
  background: #b3afb626 !important;
  color: black !important;
}

.multiselect__option--selected {
  background: #b3afb6 !important;
  color: black !important
}

.multiselect__option--selected.multiselect__option--highlight:after {
  background: #b3afb626 !important;
  content: attr(data-deselect);
  color: black !important
}

.multiselect__option--highlight:after {
  content: attr(data-select);
  background: #f4f3f4 !important;
  color: black !important
}

.multiselect__option--selected:after {
  content: attr(data-selected);
  color: inherit !important;
  background: inherit;
}

.multiselect__tag {
  background: #613c86 !important;
}

.multiselect__tags {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: none !important;
  background: #fff;
  font-size: 14px;
}

.multiselect__tag-icon:after {
  color: #fbfbfb !important;
}

.input-price:focus {
  color: #495057;
  background-color: #fff;
  border-color: none !important;
  ;
  outline: 0;
  box-shadow: none !important;
}
</style>